import { createMuiTheme } from '@material-ui/core/styles';

export default (createMuiTheme)({
    typography: {
        fontFamily: [
            '"Montserrat"',
            'sans-serif'
        ].join(','),
        fontWeightBold: 800,
        fontWeightLight: 400,
        fontWeightMedium: 600,
        fontWeightRegular: 500
    },
    MuiInputBase: {
        input: {
            fontFamily: [
                '"Montserrat"',
                'sans-serif'
            ].join(','),
            fontWeightBold: 800,
            fontWeightLight: 400,
            fontWeightMedium: 600,
            fontWeightRegular: 500
        }
    },
    overrides: {
        MuiPaper: {
            root: {
                backgroundColor: "rgba(255,255,255,0.1)",
                color: "white"
            }
        },
        MuiTypography: {
            body2: {
                fontWeight: '600'
            },
            colorTextSecondary: {
                color: "#FFD700",
                fontWeight : "normal"
            }
        },
        MuiFormLabel: {
            root: {
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: '800',
                fontSize: '0.875rem'
            }
        },
        MuiTabScrollButton: {
            root: {
                width: 0
            }
        },
        MuiTab: {
            labelIcon: {
                minWidth: '25%'
            }
        },
        MuiTextField: {
            variant: 'outlined'
        },
        MuiLinearProgress: {
            root: {
                height: 10
            }
        },
        MUIDataTable: {
            root: {
                backgroundColor: '#bbe1fa'
            },
            paper: {
                boxShadow: 'none'
            }
        },
        MUIDataTableFilterList: {
            chip: {
                margin: 16
            }
        },
        MUIDataTableToolbar: {
            root: {
                backgroundColor: '#f0efef',
                padding: 16
            }
        },
        MUIDataTableBodyRow: {
            root: {
                '&:nth-child(odd)': {
                    backgroundColor: '#f4f6ff'
                }
            }
        },
        MUIDataTableHeadCell: {
            fixedHeaderCommon: {
                backgroundColor: '#4e9bce',
                color: 'white'
            }
        },
        MuiTableHead: {
            root: {
                backgroundColor: '#62a6d4'
            }
        },
        MuiTableRow: {
            root: {
                '&:nth-child(even)': {
                    backgroundColor: '#efefef'
                }
            }
        },
        MuiTableCell: {
            head: {
                color: '#FFFFFF'
            }
        },
        MuiTableFooter: {
            root: {
                backgroundColor: '#f0efef'
            }
        },
        MuiFormHelperText: {
            root: {
                color: "whitesmoke"
            }
        },
        MuiInputBase: {
            input: {
                color: "white"
            }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: "white"
            }
        },
        MuiInputLabel: {
            root: {
                color: "white"
            }
        }
    },
    palette: {
        primary: {
            main: '#03286b',
            dark: '#022158',
            light: '#03286b',

        },
        secondary: {
            main: '#b27919',
            dark: '#b78631',
            light: '#ce9e4b'
        },
        success: {
            main: '#edffea',
            dark: '#79d70f'
        },
        info: {
            main: '#62a6d4',
        },
        error: {
            main: '#c70039',
        },
        divider: '#cdcdcd',
        background: {
            default: '#fff',
        },
    }
});
